/**
 * 工具库函数
 */


const Library = {
    // 获取地址栏参数（#前）
    getQuery(name) {
        // @params: name => 想要获取的地址栏参数（eg: www.abc.com?id=2&type=museum; getQueryFn('type')）
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        let r = window.location.search.substr(1).match(reg);
        if (r != null) {
            return decodeURIComponent(r[2]);
        }
        return null;
    },
    // 函数防抖
    debounce(method, context, time = 200, arg) {
      if(!context){
        clearTimeout(method.tId);
        method.tId = setTimeout(function() {
            method.call(context, arg);
        }, time)
        return
      }
      if(context.tId){
        clearTimeout(context.tId);
      }
      context.tId = setTimeout(function() {
          method.call(context, arg);
          context.tId = null;
      }, time)
    },

    // 正则类型验证
    typeValidate(str, type) {
        // @params: str => 验证字符串, type => 验证类型（email/idCard/phone/number/postalcode）
        let email = /^[\w|.]+[@]\w+[.][\w.]+$/;
        let idCard = /(^\d{15}$)|(^\d{17}([0-9]|X|x)$)/;
        let phone = /^1[3|4|5|6|7|8|9]\d{9}$/;
        let postalcode = /^\d{6}$/;
        let chinese = /[^\a-zA-Z\u4E00-\u9FA5]/g; // 汉字/拼音
        let number = /\D/g;
        let a_num = /\W/g; // 仅限数字、字母、下划线 等价于[^A-Za-z0-9_]
        // ams 平台通用
        let password = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,30}$/; // 密码为6～30位字符，且为字母和数字组合。（不能为纯数字或纯字母）
        let merchant_name = /^[\u4e00-\u9fa5_0-9A-Za-z]{1,50}$/; // 商户名正则 => 1～50个字符，支持中文/字母/数字/下划线
        let description = /^[\u4e00-\u9fa5_0-9A-Za-z]{1,100}$/; // 描述信息 => 1～100个字符，支持中文/字母/数字/下划线
        // ***设备名正则 (2-30个字符，暂无正则)
        let real_name = /^[\u4e00-\u9fa5_0-9A-Za-z]{2,30}$/; // 真实姓名正则 => 2～30个字符，支持中文/字母/数字/下划线
        let login_name = /^(?![0-9]+$)[0-9A-Za-z@._]{1,30}$/; // 登录姓名正则 => 1～30个字符，支持字母/数字/下划线/@/.，不能为全数字
        let device_name = /^[\u4e00-\u9fa5_0-9A-Za-z]{1,50}$/; //摄像机名称正则 => 1～50字符，可包含字母/数字/中文/下划线，可为全字母，全数字，全下划线或者全中文，不支持表情
        let gb_contact_name =  /^[\u4e00-\u9fa5_0-9A-Za-z]{1,8}$/; //国标联系人名称 => 1～8字符，可包含字母/数字/中文/下划线，可为全字母，全数字，全下划线或者全中文，不支持表情
        let person_manage_name = /^[\u4e00-\u9fa5_0-9A-Za-z]{1,50}$/; // 人员库名称正则 => 1～50字符，支持字母/数字/中文/下划线，不支持表情
        let monitor_task_name = /^[\u4e00-\u9fa5_0-9A-Za-z]{1,20}$/; // 布控任务名称正则 => 1～20字符，支持字母/数字/中文/下划线，不支持表情
        let osd = /^[\u4e00-\u9fa5_0-9A-Za-z]{1,20}$/; //OSD正则 => 1～20字符，中文/字母/数字/下划线
        let GBosd = /^[\u4e00-\u9fa5_0-9A-Za-z]{1,15}$/; //OSD正则 => 1～20字符，中文/字母/数字/下划线
        let ONVIFname = /^(?![0-9]+$)[0-9A-Za-z@._]{6,20}$/; // ONVIF名称正则 => 6～20个字符，支持字母/数字/下划线/@/.，不能为全数字
        let IP = /^((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$/;//IP地址的正则
        let domain = /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/ //域名正则
        let lib_name = /^[\u4e00-\u9fa5_0-9A-Za-z]{1,20}$/; //车辆库和人员库名称 => 1～20字符，中文/字母/数字/下划线
        let plate = /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼]{1}(([A-HJ-Z]{1}[A-HJ-NP-Z0-9]{5})|([A-HJ-Z]{1}(([DF]{1}[A-HJ-NP-Z0-9]{1}[0-9]{4})|([0-9]{5}[DF]{1})))|([A-HJ-Z]{1}[A-D0-9]{1}[0-9]{3}警)))|([0-9]{6}使)|((([沪粤川云桂鄂陕蒙藏黑辽渝]{1}A)|鲁B|闽D|蒙E|蒙H)[0-9]{4}领)|(WJ[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼·•]{1}[0-9]{4}[TDSHBXJ0-9]{1})|([VKHBSLJNGCE]{1}[A-DJ-PR-TVY]{1}[0-9]{5})$/; //车牌正则

        let bool = false;
        switch (type) {
            case 'email':
                bool = email.test(str);
                break;
            case 'idCard':
                bool = idCard.test(str);
                break;
            case 'phone':
                bool = phone.test(str);
                break;
            case 'postalcode':
                bool = postalcode.test(str);
                break;
            case 'chinese':
                bool = !chinese.test(str);
                break;
            case 'number':
                bool = !number.test(str);
                break;
            case 'password':
                bool = password.test(str);
                break;
            case 'merchant_name':
                bool = merchant_name.test(str);
                break;
            case 'real_name':
                bool = real_name.test(str);
                break;
            case 'login_name':
                bool = login_name.test(str);
                break;
            case 'device_name':
                bool = device_name.test(str);
                break;
            case 'gb_contact_name':
              bool = gb_contact_name.test(str);
                break;
            case 'person_manage_name':
                bool = person_manage_name.test(str);
                break;
            case 'monitor_task_name':
                bool = monitor_task_name.test(str);
                break;
            case 'description':
                bool = description.test(str);
                break;
            case 'osd':
                bool = osd.test(str);
                break;
            case 'GBosd':
                bool = GBosd.test(str);
                break;
            case 'ONVIFname':
                bool = ONVIFname.test(str)
                break;
            case 'IP':
                bool = IP.test(str)
                break; 
            case 'domain':
                bool = domain.test(str)
                break;  
            case 'lib_name':
                bool = lib_name.test(str)   
                break;
            case 'plate':
              bool = plate.test(str)   
              break;
            default:
                alert('未知类型');
        }
        return bool; //@return: true => 通过, false => 不通过返回
    },
    // 时间戳 ＝> 年－月－日 时：分
    timeToYMDHMS(time, type) {
        // @params: time => 时间戳（ms）
        let timeTranslate = function(num) {
            return num < 10 ? `0${num}` : num
        };
        let date = new Date(parseInt(time));
        let y = 1900 + date.getYear();
        let m = timeTranslate(date.getMonth() + 1);
        let d = timeTranslate(date.getDate());
        let h = timeTranslate(date.getHours());
        let min = timeTranslate(date.getMinutes());
        let s = timeTranslate(date.getSeconds());
        let str = '';
        switch (type) {
            case 'YY-MM-DD':
                str = `${y}-${m}-${d}`;
                break;
            case 'YY-MM-DD H:M:S':
                str = `${y}-${m}-${d} ${h}:${min}:${s}`;
                break;
            case 'H:M:S':
                str = `${h}:${min}:${s}`;
                break;
            case 'M:S':
              str = `${min}:${s}`;
                break;
            case 'objDate':
                str = { y, m, d, h, min, s };
                break;
            default:
                str = `${y}-${m}-${d} ${h}:${min}:${s}`;
                break;
        }
        return str;
    },
    // 控制浏览器全屏函数
    //进入全屏
    enterFullScreen() {
        var ele = document.documentElement;
        if (ele.requestFullscreen) {
            ele.requestFullscreen();
        } else if (ele.mozRequestFullScreen) {
            ele.mozRequestFullScreen();
        } else if (ele.webkitRequestFullScreen) {
            ele.webkitRequestFullScreen();
        }
    },
    //退出全屏
    exitFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
        }
    },
    // 动态控制元素进入全屏
    launchFullscreen(element) {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    },
    // 判断是否是全屏
    isFullScreen() {
        return document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen;
    },
    // arrary => object (eg: [label:'元素', value: 1] => {1: '元素'})，必须为lable & value
    arraryToObject(arrary, key) {
        let Obj = {};
        for (let i = 0; i < arrary.length; i++) {
            let item = arrary[i];
            Obj[item.value] = item.label;
        }
        return Obj[key];
    },
    // 获取n个月后的今天（没有当天的向前推）
    getDate(n) {
        // @params: n => 整数，表示n 个月后
        let baseMonthsDay = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; //各月天数(默认2月为平年)
        let currentDate = new Date();
        let y = currentDate.getFullYear();
        let m = currentDate.getMonth() + 1;
        let d = currentDate.getDate();
        //判断平年（28）/闰年（29）
        function isRunYear(fullYear) {
            return (fullYear % 4 == 0 && (fullYear % 100 != 0 || fullYear % 400 == 0));
        }
        // n 个月后的时间
        let later_y = y;
        let later_m = (m + n) % 12;
        if (m + n > 12) {
            later_y = later_y + 1;
        }
        let later_d = d;
        // 闰年
        if (later_m === 2 && isRunYear(later_y)) {
            baseMonthsDay[1] = 29;
        }
        // 今天大于 28 号，则取n个月的最后一天（比如，今天 12-31，取 03-30）
        if (later_d > 28) {
            later_d = baseMonthsDay[later_m - 1];
        }
        return `${later_y}-${later_m}-${later_d}`;
    },
    //检测flash是否加载 By hq  
    flashChecker() {
        var hasFlash = false; // 是否安装了flash
        var isIE = /*@cc_on!@*/ 0; //是否IE浏览器
        if (isIE) {
            var swf = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
            if (swf) {
                hasFlash = true;
            }
        } else {
            if (navigator.plugins && navigator.plugins.length > 0) {
                var swf = navigator.plugins["Shockwave Flash"];
                if (swf) {
                    hasFlash = true;
                }
            }
        }
        return hasFlash;
    },
    // 生成不重复ID
    createRandomId() {
        return (Math.random() * 10000000).toString(16).substr(0, 4) + '-' + (new Date()).getTime() + '-' + parseInt(Math.random() * 10000000).toString(16).substr(0, 4);
    },
    // 获取当前时间，并转换格式
    timeFormat(type) {
        let timeTranslate = function(num) {
            return num < 10 ? `0${num}` : num
        };
        let date = new Date();
        let y = date.getFullYear();
        let m = timeTranslate(date.getMonth() + 1);
        let d = timeTranslate(date.getDate());
        let str = '';
        switch (type) {
            case 'yyyy-mm-dd':
                str = `${y}-${m}-${d}`;
                break;
            case 'mm-dd-yyyy':
                str = `${m}-${d}-${y}`;
                break;
            case 'dd-mm-yyyy':
                str = `${d}-${m}-${y}`;
                break;
            case 'yyyy年mm月dd日':
                str = `${y}年${m}月${d}日`;
                break;
            case 'mm月dd日yyyy年':
                str = `${m}月${d}日${y}年`;
                break;
            case 'dd日mm月yyyy年':
                str = `${d}日${m}月${y}年`;
                break;
        }
        return str;
    },

    // 将 html 字符串转为 dom对象
    parseToDOM(str) {
        let divDom = document.createElement("div");
        if (typeof str == "string") {
            divDom.innerHTML = str;
        }
        return divDom.children[0]; // 默认返回第一个元素
    },
    // 深拷贝
    deepClone(obj) {
        if (obj === null || typeof obj !== 'object') return obj;
        var cpObj = obj instanceof Array ? [] : {};
        for (var key in obj) cpObj[key] = this.deepClone(obj[key]);
        return cpObj;
    },
    // 判断两个数组是否相等(数组内只包含一层对象，当前不支持多层对象数据判断)
    judgeArraryEqual(arr1, arr2) {
        let array1 = JSON.parse(JSON.stringify(arr1)).sort();
        let array2 = JSON.parse(JSON.stringify(arr2)).sort();
        return JSON.stringify(array1) == JSON.stringify(array2);
    },
    // 查询从now 时刻开始前 days 天的 00:00:00时间戳
    getLatestDayStartedFromNow(days, now) {
        let latestDayFromNow = moment(now).subtract(days, 'day').format('x');
        let theDayStarted = moment(moment(Number(latestDayFromNow)).format('YYYY-MM-DD 00:00:00')).format('x');
        return Number(theDayStarted);
    },
    // 查询一维数组重复出现的数据的次数 eg:  ["李","弟","李"] => {李: 2, 弟: 1}
    getRepeatNum(arr) {
        return arr.reduce(function(prev, next) {
            prev[next] = (prev[next] + 1) || 1;
            return prev;
        }, {});
    },
    // 01:00:00 时分秒格式转换为ms
    timeToMs(time) {
        let ms = '';
        let hour = time.split(':')[0];
        let minutes = time.split(':')[1];
        let second = time.split(':')[2];
        ms = (Number(hour * 3600) + Number(minutes * 60) + Number(second)) * 1000;
        return ms;
    },
    // ms 转换为 时分秒
    msToTime(ms) {
        let time = '';
        ms = ms / 1000;

        if (ms > -1) {
            let hour = Math.floor(ms / 3600);
            let minutes = Math.floor(ms / 60) % 60;
            let second = ms % 60;

            time = hour < 10 ? '0' + hour + ':' : hour + ':';
            time += minutes < 10 ? '0' + minutes + ':' : minutes + ':';
            time += second < 10 ? '0' + second : second;
            return time;
        }
    },
    //将字符串时间转为Unix时间（任意格式都可以，）
    timeToUnix(val){
      val=val+":00:00:00:00:00:00"
      let array = val.split(/-|:/)
      array = array.map(item=>+item)
      return parseInt(new Date(array[0],array[1]-1,array[2],array[3],array[4],array[5]).getTime()/1000);
    },
    //数字位数不够补0
     PrefixZero(num, n) {
      return (Array(n).join(0) + num).slice(-n);
    },
    //获取一个时间段返回的星期array,传入ms[周一。。。。周日]
    weekRange(starttime,endtime){
      let arr = [false,false,false,false,false,false,false];
      let index ;
      starttime = new Date(new Date(starttime).setHours(0,0,0,0)).getTime()
      for(let i=0; i<7; i++){
        let time = starttime + i * 1000*60*60*24
        if(time>endtime){
          break;
        }
        index=new Date(time).getDay()
        arr[index]=true
      }
      let Sunday = arr.shift();
      arr.push(Sunday)
      return arr
    },
    // 根据秒数据转换成 HH:mm:ss格式的Date数据
    formatSecond(value) {
      let secondTime = parseInt(value);
      let minuteTime = 0;
      let hourTime = 0;
      if (secondTime > 60) {
          minuteTime = parseInt(secondTime / 60);
          secondTime = parseInt(secondTime % 60);
          if (minuteTime > 60) {
              hourTime = parseInt(minuteTime / 60);
              minuteTime = parseInt(minuteTime % 60);
          }
      }
      let d = new Date();
      d.setHours(hourTime, minuteTime, secondTime)
      return d;
    },
    //计算自适应最大宽度
    calcItemWidth(width, min) {
      // 列数
      if (width > 0) {
        const maxColumn = parseInt(String(width / min), 10);
        // 规则是以最大列数为标准 能多放尽量多放
        return width / maxColumn;
      } else {
        return min;
      }
    },
    //删除连续重复元素
    deleteTheSeriesSame(arr){
      if(arr.length==0)return[]
      let result=[arr[0]];
      arr.reduce((temp,item,index,arr)=>{if(arr[index].toString()!=arr[index-1].toString())result.push(item)})
      return result
    },
    //根据坐标获取点之间距离
    getTheSpeed(arr){
      let array=[]
      arr.reduce((temp,item,index,arr)=>{array.push(Math.abs(Math.pow(Math.pow(arr[index][0]-arr[index-1][0],2)+Math.pow(arr[index][1]-arr[index-1][1],2),0.5)))})
      return array
    },
    translateRequestObject(requestData,showAll,keepArr){
      //showAll参数是指，在值为空数组或者空字符串时，是否还添加这个字段。目前业务只考虑数组和字符串和数字,true添加，false不添加,默认false
      showAll=showAll===undefined?false:showAll
      //keepArr参数是指，数组是变成string还是保持arr,默认false
      keepArr=keepArr===undefined?false:keepArr
      let obj = {}
      for(let key in requestData){
        let value = requestData[key]
        if(value instanceof Array){
          if(value.length==0&&!showAll) continue;
          if(!keepArr){
            obj[key] = value.join(",");
          }else{
            obj[key] = value
          }
        }
        else if(typeof(value)=='string'){
          if(value==''&&!showAll) continue;
          obj[key] = value
        } 
        else if(typeof(value)=='number'){
          obj[key] = value
        }else if(typeof(value)=='boolean'){
          obj[key] = value
        }
      }
      return obj
    },
    deleteLocalStorageIncludedKey(key){
      if (typeof key != 'string') return
      Object.keys(localStorage).forEach(item => item.indexOf(key) !== -1 ? localStorage.removeItem(item) : '');
    },
    getMeasure(el){
      return el.getBoundingClientRect();
    },
};

export default Library;