import https from '../utils/https'

// 获取商品列表
export function fetchCommoditylist(params) {
  return https.fetchGet('/api/commodity/list', params)
}

// 会员兑换商品
export function fetchExchangeOrder(params) {
  return https.fetchGet('/api/member/exchangeorder', params)
}

// 获取商品详情
export function fetchCommodityDetail(params) {
  return https.fetchGet('/api/commodity/info', params)
}

// 获取任务列表
export function fetchMemberlist(params) {
  return https.fetchGet('/api/member/tasklist', params)
}

// 获取项目信息
export function fetchProjectinfo(params) {
  return https.fetchGet('/api/member/projectinfo', params)
}

// 获取项目链接接口
export function fetchProjectTask(params) {
  return https.fetchGet('/api/member/task', params)
}